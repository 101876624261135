import { useStage } from "@/contexts/StageProvider"
import { useUserInformation } from "@/contexts/UserProvider"
import LoadingSvg from "@/assets/loading.svg"
import classNames from "classnames"
import { signIn } from "next-auth/react"
import { useState } from "react"
import { UserCircleIcon } from "@heroicons/react/24/outline"
import LogOutIcon from "@/public/assets/feather/log-out.svg"
import { usePathname } from "next/navigation"

export const LoginButton = () => {
  const { isLoggedIn } = useUserInformation()
  const { stage } = useStage()
  const [clicked, setClicked] = useState(false)
  const path = usePathname()
  const className = 'border w-fit whitespace-nowrap border-navy-600 rounded p-3 transition-all'
  const darkMode = 'bg-navy-600 text-white hover:bg-white hover:text-navy-600'
  
  if (['production', 'preprod'].includes(stage)) {
    return <a className={classNames(className, 'hover:bg-navy-600 hover:text-white')} href="https://www.acquia.com/" target="_blank">Acquia.com</a>
  }

  const btnClassName = 'hover:text-white border w-fit whitespace-nowrap border-white rounded-full p-3 transition-all duration-300'

  if (isLoggedIn) {
    return <a className={classNames(btnClassName, 'hover:bg-pink-800 text-pink-800')} href={`/api/auth/signout?callbackUrl=${path}`} title="Sign out">
      <span className="sr-only">Logout</span>
      <LogOutIcon className="w-[24px]" />
    </a>
  }

  function onClick() {
    setClicked(true)
    signIn("okta")
  }

  return <>
  <button disabled={clicked} title="Log into Acquia" className={classNames(clicked ? 'opacity-50' : 'hover:bg-navy-600 hover:text-white', 'border w-fit whitespace-nowrap border-white rounded-full p-3 transition-all duration-300')} onClick={onClick}>
    {clicked ? <LoadingSvg className="w-[24px]" title="Please wait" /> : <UserCircleIcon className="w-[24px]" />}
  </button>
  </>
}